<template>
  <div class="con-vs-alert mb-3 con-vs-alert-danger" v-if="isActive && validationErrors.length > 0">
    <div class="vs-alert">
      <p class="font-bold mb-1 text-sm">Data tidak valid!</p>
      <ul class="list-disc list-inside">
        <li class="text-sm" v-for="(item, index) in validationErrors" :key="index">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['errors'],
  data () {
    return {
      isActive: false
    }
  },
  computed: {
    validationErrors () {
      if (this.errors === null) return []
      let errors = Object.values(this.errors)
      errors = errors.flat()
      return errors
    }
  },
  watch: {
    errors (newVal) {
      this.isActive = (newVal !== null)
    }
  }
}
</script>
