import httpClient from '@/utilities/http/http-client'
import _ from 'lodash'

const endpoint = 'api/v1/proyek'

export default {
  get (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  update (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  },

  getAll (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/all${queryParams}`)
  },

  getAllWithSiteplan (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/allWithSiteplan${queryParams}`)
  }
}
